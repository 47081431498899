import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requestHandler from "../utilities/requestHandler";

const LeftMenu = () =>{

  const [loggedIn, setLoggedIn] = useState(false)
  const [token, setToken] = useState(localStorage.getItem('access_token'));
  const [account_type, setAccountType] = useState(localStorage.getItem('account_type'));

  const checkIfLoggedIn = async () => {
    let x = await requestHandler.get('auth/logged/user', true);
    if(x != null && x.status === 1){
        let y = x.message;
        setLoggedIn(true)
    }
}

useEffect(()=>{
checkIfLoggedIn()
},[]);

    return (

        <div class="col-auto d-none d-lg-flex">
          <div style={{display: loggedIn? 'flex' : 'none'}}>
          <ul class="sw-25 side-menu mb-0 primary" id="menuSide">
            <li>
              <Link to="/">
                <i data-cs-icon="home" class="icon" data-cs-size="18"></i>
                <span class="label">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/recent">
                <i data-cs-icon="news" class="icon" data-cs-size="18"></i>
                <span class="label">Recent</span>
              </Link>
            </li>
            <li>
              <Link to="/user/contents/shared">
                <i data-cs-icon="share" class="icon" data-cs-size="18"></i>
                <span class="label">Shared</span>
              </Link>
            </li>
            <li>
              <Link to="/user/referrals">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Referrals</span>
              </Link>
            </li>

<div style={{display: account_type === 'Admin' || account_type === 'Super Admin' ? 'flex' : 'none'}}>
            <li>
              <a href="#" data-bs-target="#admin">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Admin</span>
              </a>
              <ul>
             {/* <li>
                  <Link to="/admin/overview">
                    <i data-cs-icon="trend-up" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Overview</span>
                  </Link>
    </li> */}
                <li>
                  <Link to="/admin/announcement/all">
                    <i data-cs-icon="speaker" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Announcements</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/announcement/new">
                    <i data-cs-icon="plus" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">New Announcement</span>
                  </Link>
                </li>
                
                <li>
                  <Link to="/admin/users">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Influencers</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/users/top">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Top Influencers</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/list">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Admin</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/app/info">
                    <i data-cs-icon="square" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">App Info</span>
                  </Link>
                </li>
                {/*<li>
                  <Link to="/admin/app/suspend">
                    <i data-cs-icon="flash" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Suspend App</span>
                  </Link>
                </li>*/}
              </ul>
            </li>
            </div>

<div style={{display: account_type === 'Admin' || account_type === 'Super Admin' ? 'flex' : 'none'}}>
            <li>
              <a href="#" data-bs-target="#content">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Content</span>
              </a>
              <ul>
              <li>
                  <Link to="/content/all">
                    <i data-cs-icon="notebook-1" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Contents</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/new">
                    <i data-cs-icon="plus" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Create New</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/filter">
                    <i data-cs-icon="share" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Influencers Share Reports</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/filter/all">
                    <i data-cs-icon="calendar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Contents Report</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/pending">
                    <i data-cs-icon="calendar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Pending</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/shared">
                    <i data-cs-icon="share" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Shared</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/not-shared">
                    <i data-cs-icon="info-hexagon" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Not Shared</span>
                  </Link>
                </li>
              </ul>
            </li>
            </div>

            <li>
              <a href="#" data-bs-target="#profile">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Account</span>
              </a>
              <ul>
              <li>
                  <Link to="/user/profile">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Profile</span>
                  </Link>
                </li>
               {/* <li>
                  <Link to="/user/profile/overview">
                    <i data-cs-icon="file-chart" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Overview</span>
                  </Link>
              </li> */}
                <li>
                  <Link to="/user/profile/password/update">
                    <i data-cs-icon="shield" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Update Password</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          </div>
        </div>
    )
}
export default LeftMenu;