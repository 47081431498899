import React, { useEffect, useRef, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import countriesAndDialCodes from "../../utilities/countries"
import Images from "../../utilities/images"
import { ToastContainer, toast } from "react-toastify"
import Loading from "../../utilities/Loading"
import urls from "../../utilities/urls"
import { Link, useParams } from "react-router-dom"
import number_format from "../../utilities/number_format"

const EditUser = () => {
    let token = localStorage.getItem('access_token')
    const {id} = useParams();
    const {username} = useParams();
    const inputRef = useRef(null);
    const [pageTile, setPageTitle] = useState("User Profile")
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [dob, setDob] = useState(null)
    const [gender, setGender] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [country, setCountry] = useState(null)
    const [accountType, setAccountType] = useState(null)
    const [countries, setCountries] = useState(countriesAndDialCodes)
    const [picture, setPicture] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [editPost, setEditPost] = useState(false)
    let account_type = localStorage.getItem('account_type')

    const [contentsShared, setContentsShared] = useState(0)
    const [referrals, setReferrals] = useState([])
    const [shared, setShared] = useState(0)
    const [viewed, setViewed] = useState(0)
    const [allViews, setAllViews] = useState(0)

    const getRefs = async (usr) => {
      setLoading(true)
      let x = await requestHandler.get('admin/user/100/'+usr+'/referrals', true);
      if(x != null && x.status === 1){
          setReferrals(x.message)
      }
      else{
         // toast.error(x.message)
      }
      setLoading(false)
    }

    const userStats = async () => {
        let x = await requestHandler.get('admin/user/'+id+'/stats', true);
        if(x != null && x.status === 1){
            let y = x.message;
            //setUserData(y)
            setShared(y.shares)
            setViewed(y.clicks)
            setAllViews(y.total_clicks)
            setContentsShared(y.total_shares)
            getSharedContentsDetails(y.user_id)
        }
      }

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 0){
          window.location.href = "/auth/login/redirect"
      }
    }

    const uploadImage = (img) => {
        setLoading(true)
      const formData = new FormData();
//      formData.append('name', fileX.name);
      formData.append('file', img, img.name);
    console.log(formData)

        const postOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            contentType: false,
            processData: false,
            body: formData
        };

        fetch(urls.apiurl + 'user/profile/update/picture', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 1) {
                    toast.success(json.message)
                    setLoading(false)
                    //window.location.href = '/user/profile/update'
                }
                else {
                    toast.error(json.message)
                    setLoading(false)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }

    const handleFileInput = (e) => {
        setLoading(true)
        if(e) {
        setPicture(URL.createObjectURL(e[0]));

        //handle file upload
      uploadImage(e[0]);

        console.log('files: ', e);
        }
      
      };

    const userProfile = async () => {
        let x = await requestHandler.get('admin/users/'+username+'/filter', true);
        if(x != null && x.status === 1){
            let y = x.message;
            //setUserData(y)
            getRefs(y.username)
            setEmail(y.email)
            setPhone(y.phone)
            setFirstName(y.first_name)
            setLastName(y.last_name)
            setCountry(y.country)
            setPicture(y.picture)
            setDob(y.dob)
            setGender(y.gender)
            setAccountType(y.account_type)
        }
      }


    const updateProfile = async () =>{
        let url = 'admin/user/profile/update';
        setLoading(true)
        let x = await requestHandler.post(url, {id: id, email: email, phone: phone, first_name: firstName, last_name: lastName, gender: gender, country: country, dob: dob}, false, true)
            if(x != null && x.status === 1){
                toast.success(x.message)
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

    const makeAdminBtn = async () =>{
        let txt = "Are you sure you want to make user an Admin?";
        if(accountType === "Admin") txt = "Are you sure you want to remove user as Admin?"
        let canDelete = window.confirm(txt)
        if(canDelete){

        }
        let url = 'admin/update-role';
        setLoading(true)
        let role = "Admin"
        if(accountType === "Admin") role = "Influencer"
        let x = await requestHandler.post(url, {id: id, role: role}, false, true)
            if(x != null && x.status === 1){
                setAccountType(role)
                toast.success(x.message)
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

    const deleteBtn = async () => {
        let canDelete = window.confirm("Do you want to delete user?")
        if(canDelete){

        setLoading(true)
        let x = await requestHandler.get('admin/user/'+username+'/delete', true);
        if(x != null && x.status === 1){
            toast.success(x.message)
            window.location.href="/admin/users"
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
    }

      }

      const [postDetails, setPostDetails] = useState([])
      const getSharedContentsDetails = async (id) => {
        setLoading(true)
        let x = await requestHandler.get('admin/user/shared/'+id+'/details', true);
        if(x != null && x.status === 1){
            //console.log(x.message)
            setPostDetails(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

    
    useEffect(() => {
        document.title = pageTile
        userProfile()
        userStats()
    },[pageTile])

    return(
        <div className="col">
        <h2 class="small-title">Profile</h2>
        <div style={{display: !editPost && account_type === "Super Admin"? 'flex' : 'none'}} class="col-12 col-md-12 d-flex align-items-start justify-content-start m-1">
                <button onClick={() => editPost? setEditPost(false) : setEditPost(true) } type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto m-1">
                  <span>Edit</span>
                </button>
                <button onClick={() => deleteBtn()} type="button" class="btn btn-danger m-1">Delete</button>
                <button style={{display: accountType !== "Admin"? 'flex' : 'none' }} onClick={() => makeAdminBtn()} type="button" class="btn btn-info m-1">Make Admin</button>
                <button style={{display: accountType === "Admin"? 'flex' : 'none' }} onClick={() => makeAdminBtn()} type="button" class="btn btn-warning m-1">Remove Admin</button>
              </div>

              <div style={{display: editPost? 'none' : 'flex'}} class="card mb-5">
                <div class="card-body">
                  <div class="d-flex align-items-center flex-column mb-4">
                    <div class="d-flex align-items-center flex-column">
                      <div class="sw-13 position-relative mb-3">
                      <img src={picture !== null? picture :  Images().profileImage} class="img-fluid rounded-xl" alt="thumb" />
                      </div>
                      <div class="h5 mb-0">{firstName} {lastName}</div>
                      <div class="text-muted">{accountType}</div>
                      <div class="text-muted">
                        <i data-cs-icon="pin" class="me-1"></i>
                        <span class="align-middle">{country}</span>
                      </div>
                    </div>
                  </div>
                  <div class="nav flex-column" role="tablist">
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#overviewTab" role="tab">
                      <i data-cs-icon="email" class="me-2" data-cs-size="17"></i>
                      <span class="align-middle">{email}</span>
                    </a>
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#projectsTab" role="tab">
                      <i data-cs-icon="phone" class="me-2" data-cs-size="17"></i>
                      <span class="align-middle">{phone}</span>
                    </a>
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
                      <i data-cs-icon="calendar" class="me-2" data-cs-size="17"></i>
                      <span class="align-middle">{dob}</span>
                    </a>
                  </div>
                </div>
              </div>

              <div style={{display: editPost? 'flex' : 'none'}} class="card mb-5">
                <div class="card-body">
                  <form>
                  <div class="mb-3 row">
                  <div className="d-flex justify-content-center">
                    <div class="sw-13 position-relative mb-3">
                        <img src={picture !== null? picture :  Images().profileImage} class="img-fluid rounded-xl" alt="thumb" />
                        </div>
                      </div>
                      
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Update Picture</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        {isLoading? <Loading/> : 
                        <input className="form-control" accept="image/*" style={{display: 'flex'}} ref={inputRef} type="file" onChange={e => handleFileInput(e.target.files)} />
                        }  
                      
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">First Name</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="text" class="form-control" onChange={e => setFirstName(e.target.value)} value={firstName} />
                      </div>
                    </div>                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Last Name</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="text" class="form-control" onChange={e => setLastName(e.target.value)} value={lastName} />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Gender</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <select onChange={e => setGender(e.target.value)} class="form-control" data-width="100%" id="genderSelect">
                          <option value={''}>Select</option>
                          <option value={'Male'}>{'Male'}</option>
                          <option value={'Female'}>{'Female'}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Country</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <select onChange={e => setCountry(e.target.value)} class="form-control" data-width="100%" id="genderSelect">
                          <option value={country}>{country}</option>
                          {
                            countries.map((x) => {
                            return (<option value={x.country}>{x.country}</option>)  
                            })
                        }
                        </select>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Phone</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="tel" class="form-control" onChange={e => setPhone(e.target.value)} value={phone} />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Birthday</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="date" class="form-control" onChange={e => setDob(e.target.value)} value={dob} />
                      </div>
                    </div>
                    
                    {/* <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Bio</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <textarea class="form-control" rows="3">I'm a Cyborg, But That's OK</textarea>
                      </div>
                    </div> */}
                    <div class="mb-3 row mt-5">
                      <div class="col-sm-8 col-md-9 col-lg-10 ms-auto">
                      {isLoading? <Loading/> : 
                        <button onClick={updateProfile} type="button" class="btn btn-primary">Update</button>
                        }                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="mb-2"></div>
              <h2 class="small-title">Shared Contents</h2>
              <div class="card mb-5">
                        <div class="card-body mb-n2 border-last-none h-100">
            {postDetails.map(x => <div class="border-bottom border-separator-light mb-2 pb-2">
                            <div class="row g-0 sh-6">
                              <div class="col">
                                <div class="card-body d-flex flex-row pt-0 pb-0 ps-3 pe-0 h-100 align-items-center justify-content-between">
                                  <div class="d-flex flex-column">
                                    <div class="text-normal text-primary">{x.post_title}</div>
                                    
                                    <div class="row g-0">
                                    <div class="col">
                          <i class="fa-regular fa-eye m-1"></i> 
                            <span class="align-middle">{number_format(x.clicks)}</span>
                                    </div>
                                    <div class="col">
                          <i class="fa-regular fa-share-nodes m-1"></i> 
                            <span class="align-middle">{number_format(x.shares)}</span>
                                    </div>
                                    </div>

                                  </div>
                                  
                                  <div class="d-flex">
                                    <Link to={'/content/'+x.unique_id+'/view'} class="btn btn-outline-secondary btn-sm ms-1" type="button">View Content</Link>
                                   </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        </div>
                        </div>
            

              <div class="row g-2">                    
                    
              {/* <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>CONTENTS SHARED</span>
                            <i className="fa-regular fa-share-nodes text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{contentsShared}</div>
                        </div>
                      </div>
                      </div> */}

<div class="col-12 col-sm-6 col-lg-6">
  <Link to={"/admin/user/"+username+"/referrals"}>
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>REFERRALS</span>
                            <i className="fa-regular fa-users text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{referrals.length}</div>
                        </div>
                      </div>
                      </Link>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>CONTENTS VIEWED</span>
                            <i className="fa-regular fa-laptop text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{viewed}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>LINKS SHARED</span>
                            <i className="fa-regular fa-link text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{shared}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>LINKS OPENED</span>
                            <i className="fa-regular fa-eye text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{allViews}</div>
                        </div>
                      </div>
                    </div>

                   
                    
                    
                  </div>
                  

        </div>  
    )
}

export default EditUser;