import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";

const HomeContents = () => {

    const [pageTile, setPageTitle] = useState("Contents")
    const [isLoading, setLoading] = useState(false)
    const [posts, setPosts] = useState([])
    const [constPosts, setConstPosts] = useState(20)

      const getContents = async () => {
        setLoading(true)
        let x = await requestHandler.get('content/20/list', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const getMoreContents = async () => {
        setLoading(true)
        let newConstPosts = constPosts + 20;
        setConstPosts(newConstPosts)
        let x = await requestHandler.get('content/'+newConstPosts+'/list', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        getContents()
        //document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
            {isLoading? <Loading/> : ''}
            <div class="row mb-5">
                {posts.map(x => 
                <div class="col-md-6">
                    <div className="m-1">
                  <div class="card h-100">
                    {x.post_image != null? <img src={x.post_image} class="card-img-top sh-19" alt="card image" />
                :
                <video src={x.post_video} className="card-img-top" alt="video"></video>    
                }
                    
                    <div class="card-body">
                      <h5 class="heading mb-3">
                        <Link to={"/content/"+x.unique_id+"/view"} class="body-link ">
                          <span class="clamp-line sh-5" data-line="2">{truncate(x.post_title, 45)}</span>
                        </Link>
                      </h5>
                      <div>
                        <div class="row g-0">
                        <div class="col-auto pe-3">
                          <i class="fa-regular fa-eye m-1"></i> 
                            <span class="align-middle">{number_format(x.clicks)}</span>
                          </div>
                          <div class="col">
                          <i class="fa-regular fa-share m-1"></i> 
                            <span class="align-middle">{number_format(x.shares)}</span>
              </div>
                          <div class="col">
                          <i class="fa-regular fa-clock m-1"></i> 
                            <span class="align-middle"><TimeInWords time={x.duration} /> </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                )}
                
        </div>

        {isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
        }
        </div>  
    )
}

export default HomeContents;