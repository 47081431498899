
import { useEffect, useState } from "react";
import Loading from "../../utilities/Loading";
import HomeContents from "./homeContents";
import PopularContent from "./popularContent";
import requestHandler from "../../utilities/requestHandler";
import UserOverview from "../user/userOverview";

const RecentContentsForUsers = () => {
    const [pageTile, setPageTitle] = useState("Recent Contents")
    const [announcement, setAnnouncement] = useState([])
    const [popUp, setPopUp] = useState(false)
    const [lastPopId, setLastPopId] = useState(localStorage.getItem('last_pop_id'))

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
      }


    useEffect(() => {
    document.title = pageTile
    },[pageTile])
    useEffect (()=>{
        checkIfLoggedIn()    
    },[announcement])

    return (
        <div class="col">
           
            <div className="row">
            <div class="col-md-6 col-xl-8 col-xxl-9 mb-5">  

                <HomeContents />
                
            </div>
            <div class="col-md-1 col-xl-4 col-xxl-9 mb-5">
            <PopularContent/>
            </div>
            </div>
            </div>
    )
}

export default RecentContentsForUsers;