import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"

const FAQ = () => {

    const [pageTile, setPageTitle] = useState("Contact Us")
    
    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
        
        </div> 
    )
}

export default FAQ;